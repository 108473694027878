<template>
    <v-container fluid>
        <v-card class="rounded-lg px-5">
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="register.teamName"
                        label="Όνομα ομάδας"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="register.telephone"
                        label="Τηλέφωνο επικοινωνίας"
                        type="number"
                    />
                </v-col>
                <!-- <v-col cols="12" md="6">
                    <v-file-input
                        v-model="register.teamLogo"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Logo Ομάδας"
                    />
                </v-col> -->
                <v-col cols="12" md="6" align="center">
                    <v-checkbox v-model="terms">
                        <template v-slot:label>
                            <div class="sencondary-font">Αποδοχή των κανονισμών του τουρνουά</div>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn :disabled="!terms" large class="custom-orange white--text rounded-lg secondary-font" style="text-transform: capitalize;" @click="registerTeam()">
                        Εγγραφή
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <custom-dialog :dialog="showDialog" :title="title" :message="message" @update="closeDialog" />
    </v-container>
</template>

<script>
    import teamService from '@/services/team'
    import CustomDialog from '@/components/Dialog'
    export default {
        components: { CustomDialog },
        data() {
            return {
                register: { teamLogo: null },
                terms: false,
                showDialog: false,
                message: '',
                title: ''
            }
        },
        methods: {
            registerTeam() {
                teamService.register(this.register).then(() => {
                    this.showDialog = true
                    this.message = 'Καλή τύχη στο νέο πρωτάθλημα!'
                    this.title = 'Η εγγραφή σας ήταν επιτυχής!'
                }).catch(() => {
                    this.showDialog = true
                    this.message = 'Το συγκεκριμένο όνομα ομάδας χρησιμοποιείται ήδη!'
                    this.title = 'Η εγγραφή σας ήταν ανεπιτυχής!'
                })
            },
            closeDialog() {
                this.showDialog = false
                this.terms = false
                this.register = { teamLogo: null }
                this.message = ''
                this.title = ''
            }
        }
    }
</script>
